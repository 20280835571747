<template>
  <div class="main_component">
    <div id="nav" name="nav">
      <div class="Whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=573115658357%20&amp;text=Hola"
          target="_blank"
          ><img src="../public/Whatsapp.png"
        /></a>
      </div>
      <div class="ButtonUp">
        <a href="#nav"><img src="../public/up.png" /></a>
      </div>
      <div class="MenuNav">
        <a href="#Nosotros">Acerca de Nosotros</a
        ><a href="#Servicios">Nuestros Servicios</a
        ><a href="#Contactanos">Contacto</a>
      </div>
    </div>
    <div class="banner_container">
      <div class="parallax">
        <div class="BannerLateralFondo"></div>
        <div class="BannerLateral">
          <img class="Logo" src="../public/logo.png" />
          <p class="tituloBanner1">Sebastián</p>
          <p class="tituloBanner2">Santeiros</p>
          <p class="parrafoBanner">
            ¡Comprueba nuestro gran poder! <br />
            Te ayudaremos a traer el amor, trabajo,<br />negocios, salud y
            suerte, a través de <br />nuestros rituales y conjuros especiales.
          </p>
        </div>
      </div>
    </div>
    <div class="body" id="bodyNumeros">
      <div>
        <div
          id="Nosotros"
          style="top: 110%; display: flex; position: absolute"
        ></div>
        <div class="SolaLinea Nosotros" id="solalinea1">
          <h1 class="numero">1</h1>
          <p>
            Sebastian Santeiros, vidente de tradición espiritista, con toda una
            vida de experiencia, afamado mundialmente y conocido como el deseado
            de la gente, profeta, sacerdote litúrgico y experto en ayudar a la
            humanidad, el gurú de la nueva era, él que ayuda al necesitado, él
            que llena los platos de comida y no te echa en cara.
          </p>
        </div>
        <div
          class="SolaLinea"
          id="solalinea2"
          v-on:scroll.passive="activarItem()"
        >
          <h1 class="numero">2</h1>
          <p>
            Lo que Dios ha unido no lo separa el hombre, uno parejas del mismo
            sexo, hombre con hombre, mujer con mujer, hombre con mujer y
            viceversa; todo problema tiene solución, curación para el espíritu y
            sanación para la mente.
          </p>
        </div>
        <div
          class="SolaLinea"
          id="solalinea3"
          v-on:scroll.passive="activarItem()"
        >
          <h1 class="numero">3</h1>
          <p>
            Experto en trabajos a larga distancia, resultados inmediatos en
            tiempo récord, mil porciento garantizado; regreso al ser amado
            ligado, dominado, rendido, sometido, doblegado, comiendo de sus
            manos como cordero manso, pidiendo perdón con deseos de sexo y de
            amor. En tiempos difíciles es mejor estar en buenas manos, yo lo
            sacó de la bancarrota, de la quiebra, de la ruina. ¡Llame ya!, rompa
            las cadenas que le impiden ser feliz.
          </p>
        </div>
        <div
          class="SolaLinea"
          id="solalinea4"
          v-on:scroll.passive="activarItem()"
        >
          <h1 class="numero">4</h1>
          <p>
            Sede principal en la ciudad de Bogotá, barrio Spring, y otra sede
            aledaña, también contamos con una sede en la ciudad de Villavicencio
            Meta, y sedes en cinco municipios de Boyacá.
          </p>
        </div>
      </div>
    </div>
    <div class="bodySinFondo" style="display: flex">
      <div class="slide">
        <div class="slide-inner">
          <input
            class="slide-open"
            type="radio"
            id="slide-1"
            name="slide"
            aria-hidden="true"
            hidden=""
            checked="checked"
          />
          <div class="slide-item">
            <div class="video_container">
              <iframe
                class="video"
                src="https://www.youtube.com/embed/Wny0LQoNR6E"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <input
            class="slide-open"
            type="radio"
            id="slide-2"
            name="slide"
            aria-hidden="true"
            hidden=""
          />
          <div class="slide-item">
            <div class="video_container">
              <iframe
                class="video"
                src="https://www.youtube.com/embed/i0apTjp8XDE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <input
            class="slide-open"
            type="radio"
            id="slide-3"
            name="slide"
            aria-hidden="true"
            hidden=""
          />
          <div class="slide-item">
            <div class="video_container">
              <iframe
                class="video"
                src="https://www.youtube.com/embed/EgozqXkRpAg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <input
            class="slide-open"
            type="radio"
            id="slide-4"
            name="slide"
            aria-hidden="true"
            hidden=""
          />
          <div class="slide-item">
            <div class="video_container">
              <iframe
                class="video"
                src="https://www.youtube.com/embed/bzHu2vllQho"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <input
            class="slide-open"
            type="radio"
            id="slide-5"
            name="slide"
            aria-hidden="true"
            hidden=""
          />
          <div class="slide-item">
            <div class="video_container">
              <iframe
                class="video"
                src="https://www.youtube.com/embed/oWqJmCON4a0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <label for="slide-5" class="slide-control prev control-1">‹</label>
          <label for="slide-2" class="slide-control next control-1">›</label>
          <label for="slide-1" class="slide-control prev control-2">‹</label>
          <label for="slide-3" class="slide-control next control-2">›</label>
          <label for="slide-2" class="slide-control prev control-3">‹</label>
          <label for="slide-4" class="slide-control next control-3">›</label>
          <label for="slide-3" class="slide-control prev control-4">‹</label>
          <label for="slide-5" class="slide-control next control-4">›</label>
          <label for="slide-4" class="slide-control prev control-5">‹</label>
          <label for="slide-1" class="slide-control next control-5">›</label>
          <ol class="slide-indicador">
            <li>
              <label for="slide-1" class="slide-circulo">•</label>
            </li>
            <li>
              <label for="slide-2" class="slide-circulo">•</label>
            </li>
            <li>
              <label for="slide-3" class="slide-circulo">•</label>
            </li>
            <li>
              <label for="slide-4" class="slide-circulo">•</label>
            </li>
            <li>
              <label for="slide-5" class="slide-circulo">•</label>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="body" id="BodyNosotros">
      <h1 class="Subtitulo Servicios" id="Servicios">Nuestros Servicios</h1>
      <div class="slide2">
        <div class="slide2-inner">
          <input
            class="slide2-open"
            type="radio"
            id="slide2-1"
            name="slide2"
            aria-hidden="true"
            hidden=""
            checked="checked"
          />
          <div class="slide2-item">
            <div class="video2_container">
              <table class="tablecartas">
                <tr>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/1.png" class="carta" />
                      <p class="pCarta">
                        Yo le digo en una consulta si el enfermo sanará, si
                        enfermo morirá o si el preso saldrá libre. Le digo todo
                        desde la cuna hasta la tumba le muestro la cara oculta
                        de su enemigo, quito y pongo maleficios, solucionó
                        atraso de embarazo, retiro personas lacras, enemigos
                        peligrosos, protejo a sus hijos de malas amistades, le
                        curó un hijo o una hija en vicios y le digo la causa de
                        su mala suerte, ruina o fracaso, el mal que le hicieron
                        se lo muestro en el plato que usted come o en el vaso o
                        taza que usted toma sus alimentos.
                        <span
                          >Soy el vidente de la nueva era, el avatar de los
                          astros.</span
                        >
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/2.png" class="carta" />
                      <p class="pCarta">
                        No hay enfermos sino enfermedades, hoy mismo le digo
                        cuáles son los números que le favorecen, no para que
                        juegue, sino, para que gane. La Numerología es una
                        ciencia alternativa qué le brindará prosperidad.
                        <span>Mi numerología es la mejor.</span>
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/8.png" class="carta" />
                      <p class="pCarta">
                        Contacto con los muertos, con espíritus, sacó espíritus
                        de casas viejas, sacó demonios anclados y atados en
                        hogares, liberaciones y expulsión de demonios.
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/9.png" class="carta" />
                      <p class="pCarta">
                        Sebastian Santeiros el religioso espiritual que te
                        librara de todo mal. <br /><br />Realizo lectura del
                        tarot, palma de la mano, iris de la vista, Cerro ciudad
                        de la lengua, la planta del pie, el agua, los ángeles,
                        el péndulo, la tabla ouija, y más de 200 mancias, entre
                        ellas lectura del Feng Shui monárquico y de la cinta de
                        los espíritus o tabla de los muertos.
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <input
            class="slide2-open"
            type="radio"
            id="slide2-2"
            name="slide2"
            aria-hidden="true"
            hidden=""
          />
          <div class="slide2-item">
            <div class="video2_container">
              <table>
                <tr>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/3.png" class="carta" />
                      <p class="pCarta">
                        Nunca se ha oído decir que alguien haya venido a
                        Sebastian Santeiros sin su auxilio recibir.
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/10.png" class="carta" />
                      <p class="pCarta">
                        Sebastian Santeiros te cura, te sana y te pone en
                        victoria hoy mismo.
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/7.png" class="carta" />
                      <p class="pCarta">
                        No permita que su hogar, noviazgo o relación se destruya
                        por brujería, chismes, envidias, maldades, salamiento,
                        daños, porquerías, inmundicias o terceras personas.
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="cartaBox">
                      <img src="../public/4.png" class="carta" />
                      <p class="pCarta">
                        Lo protejo de bandidos, sicarios, de lacras, de enemigos
                        peligrosos, testigos en procesos, sacó condenados de la
                        cárcel.
                        <span>Soy pactado con el príncipe de este mundo.</span>
                      </p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <label for="slide2-2" class="slide2-control next control2-1">›</label>
          <label for="slide2-1" class="slide2-control prev control2-2">‹</label>
          <label for="slide2-2" class="slide2-control prev control2-1">‹</label>
          <label for="slide2-1" class="slide2-control next control2-2">›</label>
          <ol class="slide2-indicador">
            <li>
              <label for="slide2-1" class="slide2-circulo">•</label>
            </li>
            <li>
              <label for="slide2-2" class="slide2-circulo">•</label>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="bodySinFondo">
      <h1 class="Subtitulo">Encuéntranos</h1>
      <br />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63620.58550594735!2d-74.052712!3d4.720201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f8540a61fa431%3A0xa84974f76a628d7a!2sCra%2046%20%23135-10%2C%20Bogot%C3%A1%2C%20Colombia!5e0!3m2!1ses!2sus!4v1653257646199!5m2!1ses!2sus"
        class="Mapa"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="body">
      <table>
        <tr>
          <td>
            <div class="casibody" style="display: grid">
              <h1 class="Subtitulo Contactanos" id="Contactanos">
                Contactanos
              </h1>
              <p>
                Para mayor información, por favor completa el siguiente
                formulario:
              </p>
              <form class="form" v-on:submit.prevent="enviarCorreo()">
                <input
                  type="hidden"
                  name="_url"
                  value="https://www.santerocubano.com/#/Contactanos"
                />
                <input
                  type="text"
                  placeholder="Nombre"
                  required
                  v-model="correo.nombre"
                />
                <input
                  type="email"
                  placeholder="Correo Electronico"
                  required
                  v-model="correo.email"
                />
                <input
                  type="text"
                  placeholder="Numero de Contacto"
                  required
                  v-model="correo.numero"
                />
                <textarea
                  placeholder="Mensaje"
                  required
                  v-model="correo.mensaje"
                />
                <input type="submit" value="Enviar" class="Submit" />
              </form>
            </div>
          </td>
          <td>
            <div class="redesycontacto">
              <svg>
                <symbol id="icon-facebook2" viewBox="0 0 32 32">
                  <path
                    d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z"
                  ></path>
                </symbol>
                <symbol id="icon-instagram" viewBox="0 0 32 32">
                  <path
                    d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z"
                  ></path>
                  <path
                    d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z"
                  ></path>
                  <path
                    d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z"
                  ></path>
                </symbol>
                <symbol id="icon-twitter" viewBox="0 0 32 32">
                  <path
                    d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z"
                  ></path>
                </symbol>
              </svg>

              <p>Siguenos en nuestras <span>redes sociales</span></p>
              <div>
                <a
                  href="https://www.facebook.com/santeirosbrujo"
                  target="_blank"
                  ><svg class="icon icon-facebook2">
                    <use xlink:href="#icon-facebook2"></use></svg
                ></a>
                <a
                  href="https://www.instagram.com/sebastian_santeiro/"
                  target="_blank"
                  ><svg class="icon icon-instagram">
                    <use xlink:href="#icon-instagram"></use></svg
                ></a>
                <a
                  href="https://twitter.com/BRUJOSEBASTIAN?s=09"
                  target="_blank"
                  ><svg class="icon icon-twitter">
                    <use xlink:href="#icon-twitter"></use></svg
                ></a>
              </div>
              <p>O llamanos al:</p>
              <span>311 565 83 57</span>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
  import $ from "jquery";
  export default {
    name: "App",
    data: function () {
      return {
        correo: {
          nombre: "",
          email: "",
          numero: "",
          mensaje: "",
        },
      };
    },
    methods: {
      enviarCorreo: function () {
        let msg =
          this.correo.mensaje +
          " \n Mi correo electronico es: " +
          this.correo.email +
          " y mi numero telefonico es: " +
          this.correo.numero;
        var url = "https://formsubmit.co/ajax/c07e5f371ed249a57b9656c23b18e65c";
        var data = {
          name: this.correo.nombre,
          message: msg,
        };
        fetch(url, {
          method: "POST", // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .catch((error) => alert("Error al enviar el mensaje", error))
          .then((response) => alert("Mensaje enviado."));
      },
      createdfunc: function () {
        $(document).ready(function () {
          $("#solalinea1").hide(0);
          $("#solalinea2").hide(0);
          $("#solalinea3").hide(0);
          $("#solalinea4").hide(0);
          $(window).scroll(function () {
            var windowHeight = $(window).scrollTop();
            var contenido2 = $("#bodyNumeros").offset();
            contenido2 = contenido2.top;
            if (windowHeight >= contenido2) {
              $("#solalinea1").fadeIn(500);
              $("#solalinea2").fadeIn(1000);
              $("#solalinea3").fadeIn(1500);
              $("#solalinea4").fadeIn(2000);
            } else {
              $("#solalinea4").fadeOut(500);
              $("#solalinea3").fadeOut(500);
              $("#solalinea2").fadeOut(500);
              $("#solalinea1").fadeOut(500);
            }
          });
          // Add smooth scrolling to all links
          $("a").on("click", function (event) {
            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
              // Prevent default anchor click behavior
              event.preventDefault();
              // Store hash
              var hash = this.hash;
              // Using jQuery's animate() method to add smooth page scroll
              // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
              $("html, body").animate(
                {
                  scrollTop: $(hash).offset().top,
                },
                800,
                function () {
                  // Add hash (#) to URL when done scrolling (default click behavior)
                  window.location.hash = hash;
                }
              );
            } // End if
          });
        });
      },
    },
    directives: {
      scroll: {
        inserted: function (el, binding) {
          let f = function (evt) {
            if (binding.value(evt, el)) {
              window.removeEventListener("scroll", f);
            }
          };
          window.addEventListener("scroll", f);
        },
      },
    },
    created: function () {
      this.createdfunc();
    },
  };
</script>

<style>
  .redesycontacto {
    display: grid;
    justify-items: center;
  }
  .icon {
    margin: 0px 10px;
    cursor: pointer;
    display: inline-block;
    width: 3em;
    height: 3em;
    stroke-width: 0;
    stroke: #762528;
    fill: #762528;
    transition: all 0.5s;
  }
  .icon:hover {
    stroke: #af8a37;
    fill: #af8a37;
  }
  .form {
    display: contents;
  }
  span {
    color: #af8a37;
  }
  .pCarta {
    border-radius: 8px;
    padding: 20px;
    align-self: center;
    width: 80%;
    background-color: rgba(250, 250, 250, 0.9);
    position: absolute;
    left: 8%;
    z-index: -50;
    opacity: 0;
    transition: opacity 0.5s;
  }
  .carta {
    width: 100%;
    transition: all 0.5s;
  }
  .carta:hover {
    opacity: 0.3;
  }
  .cartaBox {
    display: flex;
    align-content: center;
  }
  .cartaBox:hover .carta {
    opacity: 0.3;
  }
  .cartaBox:hover .pCarta {
    z-index: 11;
    opacity: 1;
  }
  form {
    width: 100%;
  }
  .Submit {
    width: 20%;
    cursor: pointer;
  }
  input {
    border-radius: 5px;
    font-weight: 400;
    font-family: "Zilla Slab", serif;
    font-size: medium;
    background-color: #faf6e9;
    transition: all 0.5s;
    width: 80%;
    height: 5vh;
    text-align: center;
    border-width: 0;
    border-style: none;
    margin: 20px 0px;
    color: #af8a37;
  }
  textarea {
    border-radius: 5px;
    font-weight: 400;
    font-size: medium;
    font-family: "Zilla Slab", serif;
    background-color: #faf6e9;
    transition: all 0.5s;
    width: 80%;
    min-height: 10vh;
    height: fit-content;
    text-align: center;
    border-width: 0;
    border-style: none;
    margin: 20px 0px;
    color: #af8a37;
  }
  textarea:hover {
    color: #fff;
    background-color: #af8a37;
  }
  textarea::placeholder {
    font-weight: 400;
    font-size: medium;
    font-family: "Zilla Slab", serif;
    transition: all 0.8s;
    color: #af8a37;
  }
  textarea:hover::placeholder {
    color: #fff;
  }
  input:hover {
    color: #fff;
    background-color: #af8a37;
  }
  input::placeholder {
    font-weight: 400;
    font-size: medium;
    font-family: "Zilla Slab", serif;
    transition: all 0.8s;
    color: #af8a37;
  }
  input:hover::placeholder {
    color: #fff;
  }
  .Mapa {
    width: 100%;
    height: 70vh;
  }
  a {
    text-decoration: none;
  }
  p {
    text-align: justify;
  }
  .Logo {
    transform: rotate(42deg);
    position: absolute;
    bottom: 5%;
    right: 5%;
    /* width: 15%;
  height: 25%; */
    width: 13%;
  }
  .Whatsapp {
    position: fixed;
    display: block;
    bottom: 5%;
    left: 5%;
    width: 5%;
    height: 10%;
  }
  .MenuNav {
    z-index: 11;
    position: absolute;
    display: block;
    top: 5%;
    right: 5%;
  }
  .MenuNav a {
    margin: 0px 10px;
  }
  .Whatsapp img {
    width: 100%;
    height: 100%;
  }
  .ButtonUp {
    position: fixed;
    display: block;
    bottom: 5%;
    right: 5%;
    width: 5%;
    height: 10%;
  }
  .ButtonUp img {
    width: 100%;
    height: 100%;
  }
  .bodySinFondo {
    color: #fff;
    display: list-item;
    justify-content: center;
    padding: 5% 16%;
    min-height: 60vh;
    height: fit-content;
    background-image: url("../public/BackgroundGold.jpg");
    background-attachment: fixed;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
  }
  .video_container {
    display: flex;
    justify-content: center;
  }
  .video {
    display: block;
    width: 100%;
    height: 70vh;
  }
  .parrafoBanner {
    text-shadow: 0 0 1px #762528;
    display: block;
    position: absolute;
    left: 8%;
  }
  .SolaLinea {
    width: fit-content;
    justify-content: center;
    align-items: center;
    text-align: left;
    display: inline-flex;
  }
  .SolaLinea p {
    padding: 0px 5% 0px 5%;
  }
  .tituloBanner1 {
    height: fit-content;
    text-shadow: 0 0 2px #762528;
    position: absolute;
    width: fit-content;
    font-weight: bold;
    padding: 0px;
    margin: 0px 0px 22% 8%;
    color: #af8a37;
    /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000; */
    font-size: 60px;
    font-family: "IBM Plex Serif", serif;
  }
  .tituloBanner2 {
    height: fit-content;
    text-shadow: 0 0 2px #762528;
    position: absolute;
    width: fit-content;
    font-weight: bold;
    padding: 0px;
    margin: 0px 0px 14% 8%;
    color: #af8a37;
    /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000; */
    font-size: 60px;
    font-family: "IBM Plex Serif", serif;
  }
  .Subtitulo {
    width: 100%;
    justify-content: center;
    display: flex;
    justify-content: center;
    /* width: fit-content; */
    font-weight: bold;
    padding: 0px;
    color: #af8a37;
    /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000; */
    font-size: 50px;
    font-family: "IBM Plex Serif", serif;
  }
  .numero {
    width: fit-content;
    font-weight: bold;
    padding: 0px;
    margin: 0px 0px 0px 12%;
    color: #af8a37;
    /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000; */
    font-size: 100px;
    font-family: "IBM Plex Serif", serif;
  }
  .BannerLateralFondo {
    position: absolute;
    width: 19.7%;
    height: 100vh;
    /* background-color: rgba(250,250,250, 0.2); */
  }
  .BannerLateral {
    display: flex;
    align-items: center;
    width: 20%;
    height: 100vh;
    background-image: url("../public/puntosDorados.png");
    background-size: 10vh;
  }
  #BodyNosotros {
    display: list-item;
  }
  .casibody {
    padding: 0px 5%;
    background-color: #ffffff;
    height: fit-content;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
  .body {
    padding: 5% 16%;
    margin: -21px 0px 0px 0px;
    background-color: #ffffff;
    min-height: 100vh;
    height: fit-content;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }
  .banner_container {
    position: relative;
  }
  /* .banner {
  display: block;
  min-height: 900px;
  background: transparent;
} */
  .parallax {
    /* The image used */
    background-image: url("../public/banner2FV.png");

    /* Set a specific height */
    min-height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  body {
    padding: 0px;
    margin: 0px;
  }
  #app {
    font-weight: 600;
    font-size: larger;
    padding: 0px;
    margin: 0px;
    font-family: "Zilla Slab", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #451213;
  }

  #nav a {
    text-shadow: 0 0 1px #af8a37;
    font-family: "Zilla Slab", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    font-size: large;
    color: #af8a37;
    text-decoration: none;
    font-weight: 600;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
  .slide {
    position: relative;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
    width: 80%;
    height: 75vh;
  }

  .slide-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 75vh;
  }

  .slide-open:checked + .slide-item {
    position: absolute;
    opacity: 100;
    z-index: 8;
  }

  .slide-item {
    z-index: 7;
    width: 100%;
    height: 70vh;
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
  }

  .slide-control {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
  }

  .slide-control.prev {
    left: 2%;
  }

  .slide-control.next {
    right: 2%;
  }

  .slide-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
  }

  #slide-1:checked ~ .control-1,
  #slide-2:checked ~ .control-2,
  #slide-3:checked ~ .control-3,
  #slide-4:checked ~ .control-4,
  #slide-5:checked ~ .control-5 {
    display: block;
  }

  .slide-indicador {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
  }

  .slide-indicador li {
    display: inline-block;
    margin: 0 5px;
  }

  .slide-circulo {
    color: #828282;
    cursor: pointer;
    display: block;
    font-size: 35px;
  }

  .slide-circulo:hover {
    color: #aaaaaa;
  }

  #slide-1:checked
    ~ .control-1
    ~ .slide-indicador
    li:nth-child(1)
    .slide-circulo,
  #slide-2:checked
    ~ .control-2
    ~ .slide-indicador
    li:nth-child(2)
    .slide-circulo,
  #slide-3:checked
    ~ .control-3
    ~ .slide-indicador
    li:nth-child(3)
    .slide-circulo,
  #slide-4:checked
    ~ .control-4
    ~ .slide-indicador
    li:nth-child(4)
    .slide-circulo,
  #slide-5:checked
    ~ .control-5
    ~ .slide-indicador
    li:nth-child(5)
    .slide-circulo {
    color: #af8a37;
  }

  .slide2 {
    position: relative;
    /* box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64); */
    width: 100%;
    height: 70vh;
  }

  .slide2-inner {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 70vh;
  }

  .slide2-open:checked + .slide2-item {
    z-index: 8;
    position: absolute;
    opacity: 100;
  }

  .slide2-item {
    z-index: 7;
    display: flex;
    align-items: center;
    justify-items: center;
    width: 80%;
    height: 70vh;
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
  }

  .slide2-control {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
  }

  .slide2-control.prev {
    left: 2%;
  }

  .slide2-control.next {
    right: 2%;
  }

  .slide2-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
  }

  #slide2-1:checked ~ .control2-1,
  #slide2-2:checked ~ .control2-2,
  #slide2-3:checked ~ .control2-3,
  #slide2-4:checked ~ .control2-4,
  #slide2-5:checked ~ .control2-5 {
    display: block;
  }

  .slide2-indicador {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
  }

  .slide2-indicador li {
    display: inline-block;
    margin: 0 5px;
  }

  .slide2-circulo {
    color: #828282;
    cursor: pointer;
    display: block;
    font-size: 35px;
  }

  .slide2-circulo:hover {
    color: #aaaaaa;
  }

  #slide2-1:checked
    ~ .control2-1
    ~ .slide2-indicador
    li:nth-child(1)
    .slide2-circulo,
  #slide2-2:checked
    ~ .control2-2
    ~ .slide2-indicador
    li:nth-child(2)
    .slide2-circulo,
  #slide2-3:checked
    ~ .control2-3
    ~ .slide2-indicador
    li:nth-child(3)
    .slide2-circulo,
  #slide2-4:checked
    ~ .control2-4
    ~ .slide2-indicador
    li:nth-child(4)
    .slide2-circulo,
  #slide2-5:checked
    ~ .control2-5
    ~ .slide2-indicador
    li:nth-child(5)
    .slide2-circulo {
    color: #af8a37;
  }

  #titulo {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    font-family: "Open Sans", sans-serif;
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33),
      -1px 0px 2px rgba(255, 255, 255, 0);
  }
  @media screen and (max-width: 700px) {
    @-ms-viewport {
      width: 600px;
    }
    .Whatsapp {
      width: 50px;
      height: 50px;
    }
    .body table tr {
      display: grid;
    }
    .casibody {
      width: auto;
    }
    .slide2 {
      height: 2200px;
    }
    .slide2-inner {
      height: 2200px;
    }
    .slide2-item {
      height: 2200px;
    }
    .pCarta {
      font-size: small;
      padding: 5px;
    }
    .bodySinFondo {
      background-size: cover;
      background-position: center;
    }
    .body {
      padding: 5%;
    }
    .video {
      width: 300px;
      height: 50vh;
    }
    .video_container {
      height: 50vh;
      width: 300px;
    }
    .slide {
      height: 55vh;
      width: 300px;
    }
    .slide-inner {
      height: 55vh;
      width: 300px;
    }
    .slide-item {
      height: 50vh;
      width: 300px;
    }
    .ButtonUp img {
      width: 50px;
      height: 50px;
    }
    .ButtonUp {
      width: 50px;
      height: 50px;
    }
    .tituloBanner2 {
      margin: 0px 0px 0px 8%;
    }
    .tituloBanner1 {
      margin: 0px 0px 22% 8%;
    }
    .parrafoBanner {
      margin: 0px 0px -35% 0px;
    }
    .MenuNav {
      display: grid;
    }
    .MenuNav a {
      color: #af8a37;
      margin: 10px 0px;
    }
    .numero {
      font-size: 50px;
    }
    .Solalinea p {
      font-size: medium;
    }
    .Logo {
      width: 100px;
      height: 100px;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 800px) {
    @-ms-viewport {
      width: 900px;
    }
    .Whatsapp {
      width: 100px;
      height: 100px;
    }
    .body table tr {
      display: grid;
    }
    .casibody {
      width: auto;
    }
    .slide2 {
      height: 4200px;
    }
    .slide2-inner {
      height: 4200px;
    }
    .slide2-item {
      height: 4200px;
    }
    .pCarta {
      font-size: larger;
      padding: 5px;
    }
    .bodySinFondo {
      background-size: cover;
      background-position: center;
    }
    .body {
      padding: 5%;
    }
    .video {
      width: 500px;
      height: 50vh;
    }
    .video_container {
      height: 50vh;
      width: 500px;
    }
    .slide {
      height: 55vh;
      width: 500px;
    }
    .slide-inner {
      height: 55vh;
      width: 500px;
    }
    .slide-item {
      height: 50vh;
      width: 500px;
    }
    .ButtonUp img {
      width: 100px;
      height: 100px;
    }
    .ButtonUp {
      width: 100px;
      height: 100px;
    }
    .tituloBanner2 {
      margin: 0px 0px 0px 8%;
    }
    .tituloBanner1 {
      margin: 0px 0px 22% 8%;
    }
    .parrafoBanner {
      margin: 0px 0px -35% 0px;
    }
    .MenuNav {
      display: grid;
    }
    .MenuNav a {
      color: #af8a37;
      margin: 10px 0px;
    }
    .numero {
      font-size: 90px;
    }
    .SolaLinea p {
      font-size: 30px;
    }
    .Logo {
      width: 200px;
      height: 200px;
    }
  }
</style>
